/* global window, document */
import naja from 'naja';
import netteForms from 'nette-forms';

import Splide from '@splidejs/splide';

import reportWebVitals from './reportWebVitals';

import './scss/index.scss';
import './js/aresLoader';
import './js/choices';
import './js/cookies';
import './js/copyToClipboard';
import './js/dropdown';
import './js/exitOffer';
import './js/flash';
import './js/gtm';
import './js/nav';
import './js/order';
import './js/playVideo';
import './js/promoOffer';
import './js/recaptcha';
import './js/slider';
import './js/stickersCycle';
import './js/types';
import './forms';
import 'fslightbox';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

naja.formsHandler.netteForms = netteForms;

document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));

function initModal(modal) {
	if (!modal || !modal.classList.contains('modal')) {
		return;
	}

	const exits = modal.querySelectorAll('[data-dismiss]');
	if (exits) {
		exits.forEach((exit) => {
			exit.addEventListener('click', (e) => {
				e.preventDefault();
				modal.classList.remove('is-open');
			});
		});
	}
}

const modals = document.querySelectorAll('[data-modal]');

modals.forEach((trigger) => {
	trigger.addEventListener('click', (e) => {
		e.preventDefault();
		const modal = document.getElementById(trigger.dataset.modal);
		if (modal) {
			modal.classList.add('is-open');
		}
		initModal(modal);
	});
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const modalContainer = document.getElementById(event.detail.snippet.id);
	const modal = modalContainer.querySelector('.modal');
	initModal(modal);
});

const passwordToggle = document.querySelectorAll('.js-password-toggle');

passwordToggle.forEach((toggle) => {
	toggle.addEventListener('click', (e) => {
		e.preventDefault();
		const passwordInput = toggle.parentElement.querySelector('.js-password-input');
		const revealed = passwordInput.getAttribute('type');

		if (passwordInput && revealed === 'password') {
			passwordInput.setAttribute('type', 'text');
			toggle.classList.add('textfield__password__toggle--toggled');
		} else if (revealed === 'text') {
			passwordInput.setAttribute('type', 'password');
			toggle.classList.remove('textfield__password__toggle--toggled');
		}
	});
});

const splides = document.querySelectorAll('.splide');

if (splides) {
	splides.forEach((splide) => {
		const imagesCount = splide.querySelectorAll('.splide__slide').length;

		new Splide(splide, {
			arrows: imagesCount > 1 && splide.dataset.arrows === '1',
			autoplay: splide.dataset.autoplay !== '0',
			type: 'loop',
		}).mount();
	});
}

function scrollToTop() {
	window.scroll({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});
}

const button = document.querySelector('.js-scroll-btn');
if (button) {
	button.addEventListener('click', scrollToTop);
}

const showMoreBtns = document.querySelectorAll('.js-show-btn');
if (showMoreBtns) {
	showMoreBtns.forEach((showMoreBtn) => {
		showMoreBtn.addEventListener('click', () => {
			const section = showMoreBtn.closest('.js-section');
			const hiddenBlocks = section.querySelectorAll('.js-block-hidden');
			hiddenBlocks.forEach((hiddenBlock) => {
				hiddenBlock.classList.toggle('visually-hidden');
				if (hiddenBlock.classList.contains('visually-hidden')) {
					// eslint-disable-next-line no-param-reassign
					showMoreBtn.innerHTML = 'Zobrazit další';
					showMoreBtn.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				} else {
					// eslint-disable-next-line no-param-reassign
					showMoreBtn.innerHTML = 'Zobrazit méně';
				}
			});
		});
	});
}

const showMoreRef = document.querySelector('.js-show-ref');
const hiddenRefs = document.querySelectorAll('.js-hidden-ref');

if (showMoreRef && hiddenRefs) {
	showMoreRef.addEventListener('click', () => {
		hiddenRefs.forEach((hiddenRef) => {
			hiddenRef.classList.remove('display-md-none');
			hiddenRef.classList.remove('visually-hidden');
			showMoreRef.classList.add('visually-hidden');
		});
	});
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
